:root {
  --my-red: #dc153b;
  --anx-blue: #2394f3;
  --anx-orange: #f38322;
}

@font-face {
  font-family: circularStd;
  src: url("./fonts/CircularStd-Book.ttf");
}

@font-face {
  font-family: circularStdItalic;
  src: url("./fonts/CircularStd-BlackItalic.ttf");
}

.App {
  text-align: center;
  color: #222;
  font-family: circularStd;
  background: #f6f6f6;
  overflow: hidden;
}

a {
  color: #eee;
  text-decoration: none;
}

.header {
  position: fixed;
  height: 68px;
  width: 100vw;
  background: #fefefe;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  transition: background-color 500ms;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
}

.topHeader {
  transition: background-color 500ms;
  position: fixed;
  height: 68px;
  width: 100vw;
  background: transparent;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
}

.my-name {
  position: absolute;
  left: 40px;
  margin: 0 auto;
}

.header-content {
  min-width: 200px;
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.header-button {
  font-size: 20px;
  transition: background-color, padding, font-size, 400ms;
  color: #222;
}

.header-button:hover {
  color: var(--my-red);
  cursor: pointer;
}

.topHeader .header-button {
  font-size: 20px;
  transition: background-color, padding, font-size, 400ms;
  color: white;
  text-shadow: 1px 1px 1px black;
}

.topHeader .header-button:hover {
  cursor: pointer;
  color: var(--my-red);
}

.content {
  height: 91vh;
}

.welcome-container {
  height: 100vh;
  background-image: url(./images/portfolioHeroImage.jpeg);
  background-size: cover;
  background-position: right;
}

.welcome-cover {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.welcome-cover:hover {
  display: block;
}

#welcome-content {
  background-size: cover;
  color: #ddd;
  text-shadow: black 1px 1px 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 55px;
}

.second {
  animation-delay: 800ms;
  animation-fill-mode: forwards;
  font-size: 45px;
}

.welcome-button {
  font-size: 21px;
  color: rgba(0, 0, 0, 0.8);
  border: 3px solid #ddd;
  background: #ddd;
  width: 200px;
  margin-top: 20px;
  padding: 10px;
  transition: 300ms;
  text-shadow: none;
  position: relative;
  animation-delay: 1300ms;
  animation-fill-mode: forwards;
}

.welcome-button:hover {
  cursor: pointer;
  background: var(--my-red);
  border-color: var(--my-red);
  color: white;
}

@keyframes slide-up {
  0% {
    visibility: hidden;
    top: 50vw;
  }
  30% {
    visibility: hidden;
  }
  40% {
    visibility: visible;
  }

  100% {
    visibility: visible;
    animation-fill-mode: forwards;
    top: 0;
  }
}

.projects-container {
  height: auto;
  padding-top: 80px;
  width: 100vw;
}

.small-link {
  transition: color 400ms;
  font-size: 20px;
  text-decoration: underline;
  color: #057fd2;
}

.small-link:hover {
  color: var(--my-red);
}

.project-name {
  display: inline;
  color: #057fd2;
  text-decoration: none;
  font-size: 2em;
  transition: color 400ms, letter-spacing 400ms;
  font-family: circularStd;
  font-weight: bold;
}

.project-name:hover {
  color: var(--my-red);
  letter-spacing: 2px;
}

#project-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding-bottom: 50px;
}

.project {
  min-width: 300px;
  width: 80%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  margin: 70px 0;
  grid-column-gap: 3rem;
  position: relative;
  transition: 500ms;
}

#project-left {
  position: relative;
}

#project-right {
  position: relative;
}

.project-image {
  background-size: cover;
  background-position: center;
  height: 400px;
  min-width: 300px;
  width: 100%;
  justify-self: center;
  align-self: center;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  transition: box-shadow 300ms, transform 300ms;
}

.project-image:hover {
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
}

.background-position-left {
  background-position: left;
}

.project-description {
  text-align: left;
  min-width: 300px;
  width: 95%;
  justify-self: center;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

.project-description h3 {
  font-weight: 400;
  font-style: italic;
}

#left-description {
  justify-self: start;
}

#right-description {
  justify-self: start;
}

.project-description h1 {
  margin-top: 0;
}

.about-container {
  height: auto;
  min-height: 45vh;
  width: 100vw;
  padding: 100px 0;
}

.about a {
  color: var(--anx-blue);
  text-decoration: underline;
}

.about a:hover {
  color: var(--my-red);
}

#about-content {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;
  text-align: left;
  margin-top: 50px;
  height: auto;
}

.about {
  text-align: left;
  width: 100%;
  max-width: 575px;
  justify-self: center;
  font-size: 1.2em;
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about p {
  margin-top: 0;
  font-family: "Lato", sans-serif;
}

.about-icon {
  height: 60px;
  width: 60px;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
  transition: filter 200ms linear;
}

.about-icon:hover {
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.5));
}

.about-icon-container {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  width: 70%;
}

.about-icon-container a {
  transition: transform 200ms linear;
}

.about-icon-container a:hover {
  transform: translateY(-4px);
}

#resume-icon {
  transition: all 200ms linear;
}

#resume-icon:hover {
  transform: translateY(-4px);
  cursor: pointer;
}

p {
  font-family: "Lato", sans-serif;
}

.profile-img {
  width: 300px;
  box-shadow: 2px 5px 5px 0px rgba(0, 0, 0, 0.3);
  align-self: center;
  justify-self: center;
}

.icon-container {
  background: rgba(0, 0, 0, 0.5);
}

.icon {
  width: 50px;
  height: 50px;
  margin: 5px;
}

.contact-container {
  height: calc(100vh - 106px);
  max-height: 2000px;
  width: 100vw;
  padding-top: 106px;
  background-image: url(./images/contactImage.jpg);
  background-size: cover;
  background-position: left;
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.9);
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  width: 300px;
  width: 75%;
  max-width: 500px;
  height: 40px;
  border: none;
  margin: 5px;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  padding-left: 10px;
  border-radius: 5px;
}

input:focus {
  outline: none;
}

textarea {
  width: 298px;
  width: 75%;
  max-width: 498px;
  height: 200px;
  border: none;
  margin: 5px;
  font-size: 16px;
  font-family: "Lato", sans-serif;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 10px 0 0 10px;
  border-radius: 5px;
}

textarea:focus {
  outline: none;
}

.submit-button {
  min-width: 150px;
  width: 10%;
  max-width: 300px;
  border-radius: 5px;
  transition: 300ms;
  font-size: 16px;
  background: var(--anx-blue);
  color: white;
}

.submit-button:hover {
  cursor: pointer;
  background: var(--my-red);
}

.footer-container {
  min-height: 80px;
  background: #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-content a {
  transition: color 400ms;
  color: #222;
}

.footer-content a:hover {
  color: var(--my-red);
}

.footer-content {
  min-width: 200px;
  width: 25%;
  display: flex;
  justify-content: space-between;
}

.burger-container {
  display: none;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  transition: background-color 500ms, height 500ms, width 500ms;
  width: 60px;
  height: 60px;
  margin-right: 10vw;
}

.burger {
  display: block;
  height: 2px;
  background: #222;
  width: 30px;
  margin: 3px;
}

.burger-long {
  width: 40px;
}

.topHeader .burger {
  background: white;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.topHeader .burger-container:hover {
  background: none;
}

.burger-container:hover {
  cursor: pointer;
}

.open-menu {
  display: none;
  flex-direction: column;
  text-align: left;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 75vw;
  background: rgba(34, 34, 34, 1);
  color: white;
  transition: 301ms;
}

#open {
  transform: translateX(0);
}

#closed {
  transform: translateX(-100vw);
  transition: 301ms;
}

.open-menu h1 {
  position: relative;
  left: 50px;
  transition: color 300ms;
}

.open-menu h2 {
  position: relative;
  left: 65px;
  transition: color 300ms;
}

.open-menu h1:hover {
  cursor: pointer;
  color: #dc153b;
}

.open-menu h2:hover {
  cursor: pointer;
  color: #dc153b;
}

.open-cover {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  animation: opacity-change 300ms ease-out;
}

@keyframes opacity-change {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes menu-opening {
  0% {
    left: -100vw;
  }

  100% {
    left: 0;
  }
}

@keyframes slide-in-left {
  0% {
    left: -60vw;
    visibility: visible;
  }

  100% {
    left: 0;
    visibility: visible;
    opacity: 1;
  }
}

@keyframes slide-in-right {
  0% {
    visibility: visible;
    left: 60vw;
  }

  100% {
    visibility: visible;
    left: 0;
  }
}

#project-label {
  grid-column-start: 1;
  grid-column-end: 3;
  font-size: 38px;
}

@media only screen and (max-width: 880px) {
  .burger-container {
    display: flex;
  }

  .open-cover {
    display: block;
  }

  .header-content {
    display: none;
    width: 75%;
    font-size: 12px;
  }

  #about-content {
    grid-template-columns: 1fr;
  }

  .about {
    justify-self: center;
    position: relative;
    bottom: 10px;
    z-index: 1;
    margin-top: 15px;
    width: 90%;
  }

  .open-menu {
    display: flex;
    z-index: 5;
  }

  .project-description {
    margin: 15px;
  }

  #right-description {
    grid-row-start: 2;
    justify-self: center;
  }

  #left-description {
    grid-row-start: 2;
    justify-self: center;
  }

  .project {
    width: 95%;
  }
}

@media only screen and (max-width: 414px) {
  .project a {
    max-width: 300px;
  }

  .project {
    max-width: 100vw;
    width: 100%;
  }

  .project-description {
    width: 99vw;
    margin-left: 1vw;
  }

  .project-image {
    height: 300px;
    width: 100vw;
    justify-self: left;
  }

  #right-description {
    justify-self: start;
  }

  #left-description {
    grid-row-start: 2;
    justify-self: start;
  }
}
